
import Vue from 'vue'
export default Vue.extend({
    
    data() {
        return {
            valid: true,
            email: '',
            emailRules: [
                (v: string) => !!v || 'E-mail is required',
                (v: string) => /.+@.+\..+/.test(v) || 'Email- must be valid',
            ],
            password: '',
            passwordRules: [
                (v: string) => !!v || 'Password is required',
            ],
            show: false
        }
    },

    methods: {
        validate() {
            if(this.$refs.form && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
                this.$store.dispatch('auth/logIn', { email: this.email, password: this.password })        
            }
        },
    },

})
